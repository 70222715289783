
import { defineComponent, ref } from "vue";
import { useGtm } from "vue-gtm";
import * as ApplicationServices from "../api/helpers/Application";
import * as FacebookServices from "../api/helpers/Facebook";
import Button from "primevue/button";
export default defineComponent({
  name: "Results",
  components: {
    Button,
  },
  data() {
    return {
      newEmail: sessionStorage.getItem("newEmail"),
      leadId: sessionStorage.getItem("leadId"),
      applicationId: sessionStorage.getItem("applicationId"),
      afresponse: sessionStorage.getItem("afresponse")
    };
  },
  mounted() {
    window.scrollTo(0, 0);
    FacebookServices.pageView();
    document.title = process.env.VUE_APP_TITLE + " | Resultados";
    //
    if (this.newEmail != null) {
      const urlParams = new URLSearchParams(window.location.search);
      window.dataLayer?.push({
        leadId: this.leadId,
        applicationId: this.applicationId,
        newEmail: this.newEmail,
        eventID: sessionStorage.getItem("eventID"),
      });
      FacebookServices.lead();
      this.triggerEvent();
      sessionStorage.removeItem("newEmail");
    }

    sessionStorage.clear();
    localStorage.clear();
    const application = this.applicationId?.toString();
    ApplicationServices.update(
      { completed: true, afResponse: this.afresponse!.toString() },
      application
    );
  },
  beforeCreate() {
    const leadId = sessionStorage.getItem("leadId");
    const applicationId = sessionStorage.getItem("applicationId");
  },
  methods: {
    triggerEvent() {
      const gtm = useGtm();
      gtm?.trackEvent({
        event: "FireGTM",
      });
    },
    go() {
      this.$router.push("/");
    },
  },
});
